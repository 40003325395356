// TODO: refactor this code with promises...

const clients = document.querySelectorAll(".client");
const clientsSectionBackgroundImage = document.querySelector("#backgroundImageHolder");

const clientsArray = [...clients];
const bkgdImages = [...clients].map(function (client) {
  return client.dataset.img;
});

const preloadImages = function (imagesArray) {
  return new Promise(function (resolve, reject) {
    if (imagesArray != undefined && imagesArray != null) {
      imagesArray.forEach(function (imgURL, i) {
        let newImg = document.createElement("img");
        newImg.src = imgURL;
        clientsArray[i].dataset.img = newImg.src;
      });
      resolve(clientsArray);
    }
    reject("Images array either undefined or null");
  });
};

const fadeTransitionBackground = function (img) {
  clientsSectionBackgroundImage.style.opacity = 0;
  setTimeout(function () {
    fadeInCurrentBackground(img);
  }, 250);
};

const fadeInCurrentBackground = function (img) {
  clientsSectionBackgroundImage.src = img;
  clientsSectionBackgroundImage.style.opacity = 1;
};

const addHoverListeners = function (arr) {
  arr.forEach(function (client) {
    client.addEventListener("mouseover", function () {
      const img = this.dataset.img;
      fadeTransitionBackground(img);
    });
  });
};

// console.log(bkgdImages);
document.addEventListener("DOMContentLoaded", function () {
  preloadImages(bkgdImages)
    .then(function (clientsArray) {
      fadeInCurrentBackground(clientsArray[0].dataset.img);
      addHoverListeners(clientsArray);
    })
    .catch(function (err) {
      console.log(`ERROR: ${err.message}`);
    });
});
