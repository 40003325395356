let accTitle = document.getElementsByClassName("acc-heading");
let accContent = document.getElementsByClassName("acc-content");
let singleMode = false;

const calcPanelHeights = function () {
  for (let j = 0; j < accContent.length; j++) {
    let realHeight = accContent[j].scrollHeight;
    accContent[j].setAttribute("data-height", realHeight + "px");
    accContent[j].style.height = 0;
  }
};

window.addEventListener("load", function () {
  calcPanelHeights();

  for (let i = 0; i < accTitle.length; i++) {
    accTitle[i].onclick = function () {
      let openedAcc = this.getAttribute("href").replace("#", "");

      if (this.classList.contains("active")) {
        this.classList.remove("active");
        document.getElementById(openedAcc).style.height = 0;
        const accordionSymbolText = this.lastChild;

        accordionSymbolText.textContent = "+";

        return false;
      }

      if (singleMode) {
        for (let k = 0; k < accTitle.length; k++) {
          accTitle[k].classList.remove("active");
          const accordionSymbolText = this.lastChild;
          accordionSymbolText.textContent = "+";
        }

        for (let j = 0; j < accContent.length; j++) {
          accContent[j].style.height = 0;
        }
      }

      this.classList.add("active");
      const accordionSymbolText = this.lastChild;
      accordionSymbolText.textContent = "-";

      document.getElementById(openedAcc).style.height = accContent[i].getAttribute("data-height");

      return false;
    };
  }
});

window.addEventListener("resize", function () {
  calcPanelHeights();
});
