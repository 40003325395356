// import generateJoke from "./generateJoke";
// import tofino from './fonts/tofino-pro-personal/'
import smoothscroll from "smoothscroll-polyfill";

import "./components/scrollReveal.js";
import "./components/tabs.js";
import "./components/accordions.js";
import "./components/hoverImageReveal.js";
import "./components/navDrawer.js";
import "./styles/main.scss";
// import laughing from "./assets /laughing.svg";

// const laughImg = document.getElementById("laughImg");
// laughImg.src = laughing;

// const jokeBtn = document.getElementById("jokeBtn");
// jokeBtn.addEventListener("click", generateJoke);

// generateJoke();
smoothscroll.polyfill();
window.addEventListener("DOMContentLoaded", function () {});

// gsap.registerPlugin(ScrollTriggerPlugin);

// let tl = gsap.timeline({});
// ScrollTrigger.batch(".to-reveal", {
//   //   trigger: ".to-reveal",
//   start: "top 90%",
//   end: "top 90%",
//   scrub: true,
//   markers: true,
//   //   once: true,
//   toggleClass: "section--hidden"
// });
// gsap.to(".to-reveal", {
//   scrollTrigger: {
//     trigger: ".to-reveal",
//     start: "top 90%",
//     end: "bottom top",
//     // scrub: true,
//     markers: true,
//     toggleClass: "section--hidden"
//   }
//   //   y: 400
// });

const toReveals = gsap.utils.toArray(".to-reveal");
toReveals.forEach(el => {
  gsap.from(el, {
    scrollTrigger: {
      trigger: el,
      start: "top bottom"
    },
    opacity: 0,
    y: 100
    // duration: 1
  });
});

// const titleColorScrollProgress = gsap.utils.toArray(".color-progress-bar");
// titleColorScrollProgress.forEach(el => {
//   gsap.to(el, {
//     value: 100,
//     scrollTrigger: {
//       trigger: "article.grid",
//       scrub: 0.3
//     }
//   });
// });

// gsap.to("progress", {
//   value: 100,
//   ease: "none",
//   scrollTrigger: {
//     trigger: "#testArticle",
//     scrub: 0.3
//   }
// });

// Couldn't figure out in time...
// const labelRows = document.querySelectorAll(".has-label-row");

// labelRows.forEach(function (labelRow) {
//   const progress = labelRow.querySelector(".progress");
//   const article = labelRow.querySelectorAll("article, ul");
//   const theArticle = Array.from(article)[0];
//   console.log(theArticle);
//   theArticle.addEventListener(
//     "scroll",
//     () => {
//       // const scroll = theArticle.scrollTop + theArticle.offsetHeight;
//       const scroll = theArticle.scrollTop;
//       // const height = theArticle.scrollHeight - theArticle.scrollTop;
//       const height = theArticle.offsetHeight;
//       // const progressWidth = Math.floor((100 * scroll) / height);
//       const progressWidth = scroll / height;
//       console.log(progressWidth);
//       progress.style.width = `${progressWidth}%`;
//     },
//     { passive: true }
//   );
// });
