const tabs = document.querySelectorAll('[role="tab"]');
const tabList = document.querySelector('[role="tablist"]');

// Add a click event handler to each tab
tabs.forEach(tab => {
  tab.addEventListener("click", changeTabs);
});

// Enable arrow navigation between tabs in the tab list
let tabFocus = 0;

tabList.addEventListener("keydown", e => {
  // Move right
  if (e.keyCode === 39 || e.keyCode === 37) {
    tabs[tabFocus].setAttribute("tabindex", -1);
    if (e.keyCode === 39) {
      tabFocus++;
      // If we're at the end, go to the start
      if (tabFocus >= tabs.length) {
        tabFocus = 0;
      }
      // Move left
    } else if (e.keyCode === 37) {
      tabFocus--;
      // If we're at the start, move to the end
      if (tabFocus < 0) {
        tabFocus = tabs.length - 1;
      }
    }

    tabs[tabFocus].setAttribute("tabindex", 0);
    tabs[tabFocus].focus();
  }
});

function changeTabs(e) {
  const target = e.target;
  const parent = target.parentNode;
  const grandparent = parent.parentNode;
  let activePanelContentsContainer = document.querySelector(".active-panel-container");

  // Hide the existing content on the active content holder if there is any
  let activePanelChildContent = activePanelContentsContainer.querySelector(".grid");
  if (activePanelChildContent) {
    activePanelChildContent.style.opacity = 0;
  }

  setTimeout(function () {
    // Remove all current selected tabs
    parent.querySelectorAll('[aria-selected="true"]').forEach(function (tab) {
      tab.setAttribute("aria-selected", false);
      tab.classList.remove("active");
    });

    // Set this tab as selected
    target.setAttribute("aria-selected", true);
    target.classList.add("active");

    // Hide all tab panels
    activePanelContentsContainer.innerHTML = "";
    grandparent.querySelectorAll('[role="tabpanel"]').forEach(p => p.setAttribute("hidden", true));

    // Show the selected panel
    let activePanelID = target.id;
    activePanelID = activePanelID.slice(-1);
    const activePanelContents = grandparent.parentNode.querySelector(`#panel-${activePanelID} > .grid`);

    // Empty the active panel's contents
    if (activePanelChildContent) {
      activePanelChildContent.innerHTML = "";
    }

    // Make a copy of the triggered panel's content to append
    const clone = activePanelContents.cloneNode(true);
    // Put it on the active 'stage'
    activePanelContentsContainer.insertAdjacentElement("beforeend", clone);
    grandparent.parentNode.querySelector(`#${target.getAttribute("aria-controls")}`).removeAttribute("hidden");
  }, 500);
  // Fade the stage back in
  setTimeout(function () {
    activePanelContentsContainer.style.opacity = 1;
  }, 500);
}

// Trigger click to show first tab's contents when DOM content is ready
window.addEventListener("load", function (e) {
  document.querySelector("#tab-1").click();
});
