//---------------------------------------------
// Toggle side menu/navigation
//---------------------------------------------
const toggleNavBtn = document.querySelector("#toggle");
const navDrawer = document.querySelector("#nav-content");

toggleNavBtn.addEventListener("click", function () {
  navDrawer.classList.toggle("open");
  toggleNavBtn.classList.toggle("open");
});

//---------------------------------------------
// Smooth scrolling to section without updating URL
//---------------------------------------------
const navLinks = document.querySelectorAll("#nav-content a");

navLinks.forEach(function (navLink) {
  navLink.addEventListener("click", function (e) {
    e.preventDefault();
    const sectionID = e.currentTarget.hash;
    // console.log(sectionID);
    const toScrollTo = document.querySelector(`${sectionID}`);

    // Modern browsers way to do it
    toScrollTo.scrollIntoView({ behavior: "smooth" });
  });
});
